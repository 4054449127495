import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GlobalModalsService {
    sideCartVisible$ = new BehaviorSubject(false);

    productIdOfGlobalVariantModal$ = new BehaviorSubject<any>(null);

    constructor() {}

    // Side Cart
    showSideCart() {
        this.sideCartVisible$.next(true);
    }

    hideSideCart() {
        this.sideCartVisible$.next(false);
    }

    // Variant Selector
    showVariantSelectorModal(productId: any) {
        this.productIdOfGlobalVariantModal$.next(productId);
    }

    hideVariantSelectorModal() {
        this.productIdOfGlobalVariantModal$.next(null);
    }
}
