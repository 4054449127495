<div>
    <div class="desktop-show">
        <div
            class="banner-img"
            [ngClass]="{
                'banner-img-center': shopService.themeSettings.banner.layout === 'on_the_image',
                'banner-img-left': shopService.themeSettings.banner.layout === 'left',
                'banner-img-right': shopService.themeSettings.banner.layout === 'right'
            }"
        >
            <div class="banner">
                <img appImgLoadSmooth [lazyLoad]="shopService.themeSettings.banner.desktopImg" />
            </div>
            <div class="d-flex align-items-center shop-info shop-info-view">
                <div class="width-100p">
                    <h4 class="content-title color5 font-title" [innerHtml]="shopService.themeSettings.banner?.title | safeHtml"></h4>
                    <p class="content-text color2 font-body" [innerHtml]="shopService.themeSettings.banner?.content | safeHtml"></p>
                    <div class="text-align-center">
                        <span
                            *ngIf="shopService.themeSettings.banner.heroButtonVisible"
                            class="font-body banner-cta"
                            [ngClass]="{'corner-round ': shopService.themeSettings.cornerStyle === 'round'}"
                            [ngStyle]="{color: bannerButtonColor, background: bannerButtonBackground}"
                            [innerHTML]="shopService.themeSettings.banner.heroButtonText | safeHtml"
                            (click)="goProductList()"
                        >
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mobile-show">
        <div
            class="banner-img"
            [ngClass]="{
                'banner-img-top': shopService.themeSettings.banner.mobileTextPosition === 'top',
                'banner-img-center': shopService.themeSettings.banner.mobileTextPosition === 'center',
                'banner-img-bottom': shopService.themeSettings.banner.mobileTextPosition === 'bottom'
            }"
        >
            <div class="banner">
                <img appImgLoadSmooth [lazyLoad]="shopService.themeSettings.banner.mobileImg" />
            </div>
            <div class="d-flex align-items-center shop-info shop-info-view">
                <div class="width-100p">
                    <h4 class="content-title color5 font-title" [innerHtml]="shopService.themeSettings.banner?.title | safeHtml"></h4>
                    <p class="content-text color2 font-body" [innerHtml]="shopService.themeSettings.banner?.content | safeHtml"></p>
                    <div class="text-align-center">
                        <span
                            *ngIf="shopService.themeSettings.banner.heroButtonVisible"
                            class="font-body banner-cta"
                            [ngClass]="{'corner-round ': shopService.themeSettings.cornerStyle === 'round'}"
                            [ngStyle]="{color: bannerButtonColor, background: bannerButtonBackground}"
                            [innerHTML]="shopService.themeSettings.banner.heroButtonText | safeHtml"
                            (click)="goProductList()"
                        >
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
