import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-wrapper',
    templateUrl: './wrapper.component.html',
    styleUrls: ['./wrapper.component.less'],
})
export class WrapperComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
