import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { ScreenTrackingService, AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from '@src/environments/environment';

import { HomeModule } from './modules/home/home.module';
import { SharedModule } from '@shared/shared.module';
import { WrapperComponent } from './modules/wrapper/wrapper.component';
import { PageNotFoundComponent } from './modules/others/page-not-found/page-not-found.component';
import { AuthModule } from './modules/auth/auth.module';

registerLocaleData(en);

@NgModule({
    declarations: [AppComponent, WrapperComponent, PageNotFoundComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        HomeModule,
        AuthModule,
        AngularFireAnalyticsModule,
        AngularFireModule.initializeApp(environment.firebase),
        SharedModule,
    ],
    providers: [{ provide: NZ_I18N, useValue: en_US }, ScreenTrackingService],
    bootstrap: [AppComponent],
})
export class AppModule {}
