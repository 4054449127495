import { Directive, Input, AfterViewInit, ComponentFactoryResolver, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { ShopService } from '@services/shop.service';

@Directive({
    selector: '[appLoading]',
})
export class LoadingDirective implements AfterViewInit, OnChanges {
    @Input() loading: boolean;
    @Input() iconVisible = true;
    @Input() maskVisible: boolean;

    boxNode: HTMLElement;

    constructor(private shopService: ShopService, private el: ElementRef) {}

    ngOnChanges(e: SimpleChanges) {
        if (!e?.loading.firstChange) {
            if (this.loading) {
                this.showLoading();
            } else {
                this.hideLoading();
            }
        }
    }

    ngAfterViewInit() {
        if (this.loading) {
            this.showLoading();
        }
    }

    showLoading() {
        if (!this.el.nativeElement.style.position || this.el.nativeElement.style.position === 'static') {
            this.el.nativeElement.style.position = 'relative';
        }

        this.boxNode = document.createElement('div');
        this.boxNode.style.width = '100%';
        this.boxNode.style.height = '100%';
        this.boxNode.style.display = 'flex';
        this.boxNode.style.justifyContent = 'center';
        this.boxNode.style.alignItems = 'center';
        this.boxNode.style.position = 'absolute';
        this.boxNode.style.left = '0';
        this.boxNode.style.top = '0';
        this.boxNode.style.zIndex = '999';
        this.boxNode.style.transition = 'opacity linear 0.2s';
        if (this.maskVisible) {
            this.boxNode.style.background = 'rgba(255,255,255,0.5)';
        }

        if (this.iconVisible) {
            const img = document.createElement('img');
            img.src = this.shopService.loadingIcon;
            img.style.width = '30px';
            img.style.height = '30px';
            img.style.animation = 'spin 2s linear infinite';

            this.boxNode.appendChild(img);
        }

        this.el.nativeElement.append(this.boxNode);
    }

    hideLoading() {
        this.boxNode.style.opacity = '0';
        setTimeout(() => {
            this.boxNode.remove();
        }, 500);
    }
}
