<section class="login-box font-body color2">
    <h1 class="font-title">Login</h1>

    <div class="form-item">
        <label>Email</label>
        <input [(ngModel)]="email" nzSize="large" nz-input type="text" (ngModelChange)="loginFailedMessage = ''" />
    </div>

    <div class="form-item">
        <div class="d-flex justify-content-between align-items-center">
            <label>Password</label>
            <span class="text-decoration-underline cursor-pointer" [routerLink]="['/','reset-password']">Forgot your password?</span>
        </div>
        <input
            [(ngModel)]="password"
            nz-input
            nzSize="large"
            type="password"
            (ngModelChange)="loginFailedMessage = ''"
            (keyup.enter)="login()"
        />

        <div class="red mt-20" *ngIf="loginFailedMessage">{{loginFailedMessage}}</div>
    </div>

    <button
        class="submit-btn color1 bg-color3"
        nz-button
        nzSize="large"
        [nzLoading]="submitting"
        [disabled]="submitBtnDisabled"
        (click)="login()"
        (ngModelChange)="loginFailedMessage = ''"
    >
        SIGN IN
    </button>

    <div class="text-decoration-underline create-account">
        <span class="cursor-pointer" [routerLink]="['/','sign-up']">Create account</span>
    </div>
</section>
