import { Component, OnInit, Input } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { GlobalModalsService } from '@services/global-modals.service';
import { Router } from '@angular/router';
import { AnalyticsEventEnum, FirebaseEventService } from '@services/firebase-event.service';
import { UtilsService } from '@src/app/services/utils.service';

@Component({
    selector: 'app-product-panel',
    templateUrl: './product-panel.component.html',
    styleUrls: ['./product-panel.component.less'],
})
export class ProductPanelComponent implements OnInit {
    @Input() productInfo;

    tags: string[];

    constructor(
        public shopService: ShopService,
        public utilsService: UtilsService,
        private globalModalService: GlobalModalsService,
        private router: Router,
        private firebaseEventService: FirebaseEventService
    ) {}

    ngOnInit(): void {
        this.tags = this.productInfo?.tags?.split(',').map((item: string) => item.trim());
    }

    addToCart(productId) {
        this.globalModalService.showVariantSelectorModal(productId);
    }

    goProductDetail(productId) {
        this.logEvent();
        this.router.navigate(['/', 'product', 'product-details', productId]);
    }

    logEvent() {
        this.firebaseEventService.logEvent(AnalyticsEventEnum.SELECT_ITEM, {
            items: [
                {
                    item_id: this.productInfo.product_id,
                    item_name: this.productInfo.title,
                    price: this.productInfo.price,
                    currency: this.productInfo.currency,
                },
            ],
        });
    }
}
