import { AuthGuard } from './services/auth.guard';
import { OrderHistoryComponent } from './modules/auth/order-history/order-history.component';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { SignUpComponent } from './modules/auth/sign-up/sign-up.component';
import { PageNotFoundComponent } from './modules/others/page-not-found/page-not-found.component';
import { WrapperComponent } from './modules/wrapper/wrapper.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@modules/home/home.component';
import { ThemeSettingsResolverService } from '@services/theme-settings-resolve.service';
import { LoginComponent } from '@modules/auth/login/login.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    {
        path: '',
        component: WrapperComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'home' },
            {
                path: 'sign-in',
                canActivate: [AuthGuard],
                resolve: { themeSettings: ThemeSettingsResolverService },
                component: LoginComponent,
            },
            {
                path: 'sign-up',
                canActivate: [AuthGuard],
                resolve: { themeSettings: ThemeSettingsResolverService },
                component: SignUpComponent,
            },
            {
                path: 'reset-password',
                // canActivate: [AuthGuard],
                resolve: { themeSettings: ThemeSettingsResolverService },
                component: ResetPasswordComponent,
            },
            {
                path: 'order-history',
                canActivate: [AuthGuard],
                resolve: { themeSettings: ThemeSettingsResolverService },
                component: OrderHistoryComponent,
            },
            {
                path: 'home',
                resolve: { themeSettings: ThemeSettingsResolverService },
                component: HomeComponent,
            },
            {
                path: 'product',
                resolve: { themeSettings: ThemeSettingsResolverService },
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
            },
            {
                path: 'policy',
                resolve: { themeSettings: ThemeSettingsResolverService },
                loadChildren: () => import('./modules/policy/policy.module').then(m => m.PolicyModule),
            },
            {
                path: '*',
                redirectTo: '/home',
            },
        ],
    },
    {
        path: 'page-not-found',
        component: PageNotFoundComponent,
    },
    {
        path: '*',
        redirectTo: '/home',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
