import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { isNumber } from 'lodash';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as sameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(sameOrBefore);

interface CrowdFundingDetail {
    created_at: string;
    created_by: string;
    end_date: string;
    id: number;
    item_sold: number;
    minimal_quantity: number;
    notes: string;
    order_id: number;
    start_date: string;
    updated_at: string;
    hide_from_store: boolean;
}

@Component({
    selector: 'app-crowdfunding',
    templateUrl: './crowdfunding.component.html',
    styleUrls: ['./crowdfunding.component.less'],
})
export class CrowdfundingComponent implements OnInit, OnDestroy {
    @Input() crowdfundingDetail: CrowdFundingDetail;

    intervalId: any;

    timeNumberArray: Array<number>;

    percentageRate: number;

    formattedEndDate: string;

    shipDate: string;

    crowdfundingStatus: 'inProgress' | 'success' | 'failed';

    get shouldCrowdFundingVisible() {
        if (!this.crowdfundingDetail) {
            return false;
        }

        let shouldVisibleAfterGetData = false;

        if (this.crowdfundingStatus === 'inProgress') {
            shouldVisibleAfterGetData = true;
        }

        if (this.crowdfundingStatus !== 'inProgress') {
            shouldVisibleAfterGetData = !this.crowdfundingDetail.hide_from_store;
        }

        return shouldVisibleAfterGetData;
    }

    constructor() {}

    ngOnInit(): void {
        this.intervalHandle();
        this.intervalId = setInterval(() => this.intervalHandle(), 1000);
        this.shipDate = dayjs(this.crowdfundingDetail.end_date).add(35, 'day').format('MM/DD/YYYY');
    }

    ngOnDestroy() {
        clearInterval(this.intervalId);
    }

    intervalHandle() {
        this.timeNumberArray = this.generateTimeArr(this.crowdfundingDetail.end_date);
        this.percentageRate = Math.floor((this.crowdfundingDetail.item_sold / this.crowdfundingDetail.minimal_quantity) * 100);
        this.formattedEndDate = dayjs(this.crowdfundingDetail.end_date).tz('PST').format('MM/DD/YYYY HH:mm PST');
        this.crowdfundingStatus = 'inProgress';

        if (dayjs(this.crowdfundingDetail.end_date).isSameOrBefore(dayjs())) {
            clearInterval(this.intervalId);
            if (this.crowdfundingDetail.item_sold >= this.crowdfundingDetail.minimal_quantity) {
                this.crowdfundingStatus = 'success';
            }
            if (this.crowdfundingDetail.item_sold < this.crowdfundingDetail.minimal_quantity) {
                this.crowdfundingStatus = 'failed';
            }
        }
    }

    private generateTimeArr(date) {
        const timeArr = [];

        if (dayjs(date).isBefore(dayjs())) {
            return [0, 0, ':', 0, 0, ':', 0, 0, ':', 0, 0];
        }

        const diffTime = dayjs.duration(dayjs(date).diff(dayjs()), 'millisecond');

        const day = String(Math.floor(diffTime.asDays())).padStart(2, '0');
        const hours = String(diffTime.hours()).padStart(2, '0');
        const minutes = String(diffTime.minutes()).padStart(2, '0');
        const seconds = String(diffTime.seconds()).padStart(2, '0');

        (day + hours + minutes + seconds)
            .split('')
            .map(item => Number(item))
            .forEach((item, i) => {
                if (i > 0 && i % 2 === 0) {
                    timeArr.push(':');
                }

                timeArr.push(item);
            });

        return timeArr;
    }
}
