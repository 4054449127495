<div class="small-inventory-layout">
    <div *ngIf="productList" class="desktop-show">
        <div #swiper class="swiper-container" [swiper]="swiperConfig">
            <div class="swiper-wrapper" [class.justify-content-center]="productList.length < 3">
                <div *ngFor="let data of productList" class="swiper-slide">
                    <div style="max-width: 370px; margin: 0 auto" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                        <app-product-panel [productInfo]="data"></app-product-panel>
                    </div>
                </div>
            </div>

            <img
                *ngIf="productList?.length >= 3"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjQ1NDYgMTcuNUgxMC42MDYxIiBzdHJva2U9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xNi45Njk3IDIzLjMzMzRMMTAuNjA2MSAxNy41MDAxTDE2Ljk2OTcgMTEuNjY2NyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVqb2luPSJiZXZlbCIvPgo8Y2lyY2xlIHI9IjE3IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAxNy41IDE3LjUpIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo="
                class="swiper-left swiper-button-prev"
            />
            <img
                *ngIf="productList?.length >= 3"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjQ1NDYgMTcuNUgxMC42MDYxIiBzdHJva2U9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xNi45Njk3IDIzLjMzMzRMMTAuNjA2MSAxNy41MDAxTDE2Ljk2OTcgMTEuNjY2NyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVqb2luPSJiZXZlbCIvPgo8Y2lyY2xlIHI9IjE3IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAxNy41IDE3LjUpIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo="
                class="swiper-right swiper-button-next"
            />
        </div>
    </div>
    <div class="mobile-show" style="width: 100%">
        <div *ngFor="let data of productList" style="padding: 20px">
            <app-product-panel [productInfo]="data"></app-product-panel>
        </div>
    </div>
</div>
