import { Component, OnInit } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { Router } from '@angular/router';

import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-global-footer',
    templateUrl: './global-footer.component.html',
    styleUrls: ['./global-footer.component.less'],
})
export class GlobalFooterComponent implements OnInit {
    now = new Date();
    subscribing = false;
    subscribeError = false;
    subscribed = false;

    emailForm = new FormControl('', Validators.email);

    constructor(public shopService: ShopService, private router: Router) {}

    ngOnInit(): void {}

    navigate(page: string) {
        let url = '';

        if (page === 'privacy') {
            url = 'privacy-policy';
        }

        if (page === 'shipping') {
            url = 'shipping-policy';
        }

        if (page === 'refund') {
            url = 'refund-policy';
        }

        if (page === 'termsOfUse') {
            url = 'terms-of-use';
        }

        this.router.navigate(['/', 'policy', url]);
    }

    goHome() {
        this.router.navigate(['/', 'home']);
    }

    subscribe() {
        if (this.emailForm.value.trim().length) {
            this.subscribing = true;
            this.shopService
                .subscribeEmail(this.emailForm.value.trim())
                .then(() => (this.subscribed = true))
                .catch(() => (this.subscribeError = true))
                .finally(() => (this.subscribing = false));
        }
    }
}
