<footer class="global-footer">
    <div class="footer-content page-width">
        <div class="desktop-show column column-1">
            <img style="max-height: 100px; cursor: pointer" [src]="shopService.logo" (click)="goHome()" />
        </div>
        <div class="column column-2">
            <div class="policy-item" (click)="navigate('privacy')"><span class="hover-underline">Privacy Policy</span></div>
            <div class="policy-item" (click)="navigate('termsOfUse')"><span class="hover-underline">Terms of Service</span></div>
        </div>
        <div class="column column-3">
            <div class="policy-item" (click)="navigate('refund')"><span class="hover-underline">Refund Policy</span></div>
            <div class="policy-item" (click)="navigate('shipping')"><span class="hover-underline">Shipping Policy</span></div>
        </div>
        <div class="mobile-show column column-1 mt-20 mb-20">
            <img style="max-height: 100px; cursor: pointer" [src]="shopService.logo" (click)="goHome()" />
        </div>
        <!-- <div class="column column-4">
            <div class="fw-bold" style="font-size: 32px; line-height: 1">Newsletter</div>
            <div class="mt-20 mb-20">Sign up to stay connected.</div>
            <div class="form">
                <input
                    nz-input
                    type="email"
                    placeholder="Your Email"
                    nzSize="large"
                    class="br-0 border-tl-radius border-bl-radius"
                    [formControl]="emailForm"
                    [style.borderColor]="shopService.color3"
                />
                <button
                    nz-button
                    nzSize="large"
                    nzType="primary"
                    class="bg-color3 color1 br-0 border-tr-radius border-br-radius"
                    [nzLoading]="subscribing"
                    [disabled]="emailForm.invalid || subscribed"
                    (click)="subscribe()"
                    style="width: 160px"
                >
                    {{subscribed?'SUBSCRIBED':'SUBMIT'}}
                </button>
            </div>
            <div class="mt-10" *ngIf="subscribeError">Email Not Sent</div>
        </div> -->
    </div>
    <div class="text-align-center mt-30">
        ©{{now | date:'yyyy'}},Lifo Storefront Powered by
        <a href="https://www.lifo.ai/" target="_blank">Lifo.ai</a>
    </div>
</footer>
