import { HttpErrorResponse } from '@angular/common/http';
import { merge, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.less'],
})
export class SignUpComponent implements OnInit, OnDestroy {
    signUpFrom = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.email, Validators.required]),
        password: new FormControl('', [Validators.minLength(8), Validators.maxLength(20), Validators.required]),
        confirmPassword: new FormControl(''),
        socialPlatform: new FormControl('', Validators.required),
        socialAccount: new FormControl('', Validators.required),
    });

    submitting = false;

    socialMedias = [
        { value: 'instagram', name: 'Instagram' },
        { value: 'tiktok', name: 'TikTok' },
        { value: 'youtube', name: 'YouTube' },
        { value: 'twitter', name: 'Twitter' },
        { value: 'facebook', name: 'Facebook' },
        { value: 'snapchat', name: 'Snapchat' },
    ];

    private unsubscribe: Subject<void> = new Subject();

    constructor(private authService: AuthService, private messageService: NzMessageService, private router: Router) {}

    ngOnInit(): void {
        merge(this.signUpFrom.controls.password.valueChanges, this.signUpFrom.controls.confirmPassword.valueChanges)
            .pipe(takeUntil(this.unsubscribe), debounceTime(300))
            .subscribe(() => {
                this.signUpFrom.get('confirmPassword').markAsTouched();
                this.signUpFrom.get('confirmPassword').setErrors(this.checkPasswords(this.signUpFrom));
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    signUp() {
        this.submitting = true;
        const formRawValue = this.signUpFrom.getRawValue();
        this.authService
            .signup({
                firstName: formRawValue.firstName,
                lastName: formRawValue.lastName,
                email: formRawValue.email,
                password: formRawValue.password,
                socialMedia: formRawValue.socialPlatform,
                socialMediaAccount: formRawValue.socialAccount,
            })
            .then(() => {
                this.messageService.success('Success');
                this.router.navigate(['/', 'sign-in']);
            })
            .catch((err: HttpErrorResponse) =>
                this.messageService.error(err.error.message || 'Oops! Something went wrong. Please try again.')
            )
            .finally(() => (this.submitting = false));
    }

    private checkPasswords(group: FormGroup) {
        const pass = group.get('password').value;
        const confirmPass = group.get('confirmPassword').value;
        return !!pass && !!confirmPass && pass === confirmPass ? null : { notSame: true };
    }
}
