import { Component, OnInit, Input } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { UtilsService } from '@src/app/services/utils.service';

@Component({
    selector: 'app-text-image',
    templateUrl: './text-image.component.html',
    styleUrls: ['./text-image.component.less'],
})
export class TextImageComponent implements OnInit {
    get aboutBackground() {
        if (this.shopService.themeSettings.aboutMe?.direction === 'reverse') {
            return this.shopService.themeSettings.color5;
        } else {
            return this.shopService.themeSettings.color3;
        }
    }

    get aboutSectionMediaType() {
        return this.shopService.themeSettings.aboutMe.youtubeVideoUrl ? 'video' : 'image';
    }

    get embedYoutubeVideoUrl() {
        const url = this.shopService.themeSettings.aboutMe.youtubeVideoUrl || '';
        const urlArr = url.split('?')[0].split('/');
        const id = urlArr[urlArr.length - 1];
        return `https://www.youtube.com/embed/${id}?branding=0&showinfo=0&modestbranding=1&playsinline=1`;
    }

    get getBackgroundImage() {
        if (this.utilsService.isDesktop) {
            return this.shopService.themeSettings.aboutMe.desktopBackgroundImage;
        } else {
            return this.shopService.themeSettings.aboutMe.mobileBackgroundImage;
        }
    }

    constructor(public shopService: ShopService, public utilsService: UtilsService) {}

    ngOnInit(): void {}
}
