import { Component, Input, OnInit } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-text-image-banner',
    templateUrl: './text-image-banner.component.html',
    styleUrls: ['./text-image-banner.component.less'],
})
export class TextImageBannerComponent implements OnInit {
    get bannerHeadingColor() {
        return this.shopService.themeSettings.color5;
    }

    get bannerBodyColor() {
        return this.shopService.themeSettings.color2;
    }

    get bannerButtonBackground() {
        return this.shopService.themeSettings.color3;
    }

    get bannerButtonColor() {
        return this.shopService.themeSettings.color1;
    }

    constructor(public shopService: ShopService, private router: Router) {}

    ngOnInit(): void {}

    goProductList() {
        if (this.shopService.themeSettings.banner.heroButtonVisible) {
            this.router.navigateByUrl(this.shopService.themeSettings.banner.heroButtonLink);
        }
    }
}
