<div
    class="web-content social-media bg-color6"
    [ngStyle]="{
        'background-image': getBackgroundImage ? 'url(' + getBackgroundImage + ')' : '',
        'background-size': 'cover',
        'background-position': 'center'
    }"
>
    <h4 class="content-title color4 font-title">Social Media</h4>
    <p class="content-text color4 font-body">Let's Connect</p>
    <div class="d-flex flex-wrap justify-content-center social-media-content">
        <div *ngFor="let platform of addedSocial" class="img-box">
            <a [href]="shopService.themeSettings.socialMedia[platform]" target="_blank">
                <i nz-icon [nzType]="getNewSocialIcon(platform)" nzTheme="outline" class="img color4"></i>
            </a>
        </div>
    </div>
</div>
