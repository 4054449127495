import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ShopService } from '@services/shop.service';

export enum AnalyticsEventEnum {
    LOGIN = 'login',
    ADD_TO_CART = 'add_to_cart',
    REMOVE_FROM_CART = 'remove_from_cart',
    SIGN_UP = 'sign_up',
    VIEW_ITEM = 'view_item',
    VIEW_ITEM_LIST = 'view_item_list',
    SELECT_ITEM = 'select_item',
    BEGIN_CHECKOUT = 'begin_checkout',
}

@Injectable({
    providedIn: 'root',
})
export class FirebaseEventService {
    constructor(private analytics: AngularFireAnalytics, private shopService: ShopService) {
        this.analytics.setAnalyticsCollectionEnabled(environment.production);
    }

    logEvent(eventName: AnalyticsEventEnum, attr: { [key: string]: any }) {
        this.analytics.logEvent(eventName, {
            shop_id: this.shopService.shopDetail.id,
            ...attr,
        });
    }

    addShopIdForDefaultEvent(shopId: any) {
        this.analytics.updateConfig({ shop_id: shopId });
    }
}
