<section class="sign-up-box font-body">
    <h1 class="font-title">Create Account</h1>

    <div class="forms">
        <form nz-form [formGroup]="signUpFrom" class="sign-up-form">
            <div class="d-flex justify-content-between align-items-start form-item flex-wrap">
                <div class="form-item-child pr-20">
                    <label>First Name</label>
                    <nz-form-control nzErrorTip="Enter first name">
                        <input formControlName="firstName" nzSize="large" nz-input type="text" />
                    </nz-form-control>
                </div>

                <div class="form-item-child pl-20">
                    <label>Last Name</label>
                    <nz-form-control nzErrorTip="Enter last name">
                        <input formControlName="lastName" nzSize="large" nz-input type="text" />
                    </nz-form-control>
                </div>
            </div>

            <div class="form-item">
                <label>Email</label>
                <nz-form-control [nzErrorTip]="emailErrorTpl">
                    <input formControlName="email" nzSize="large" nz-input type="text" />
                </nz-form-control>

                <ng-template #emailErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('email')">
                        <span>Not a valid email.</span>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('required')">
                        <span>Enter your email.</span>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('registered')">
                        <span>The email is taken.</span>
                    </ng-container>
                </ng-template>
            </div>

            <div class="form-item">
                <label>Password</label>
                <nz-form-control [nzErrorTip]="passwordErrorTpl">
                    <input formControlName="password" nzSize="large" nz-input type="password" />
                </nz-form-control>

                <ng-template #passwordErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        <span>Enter your password.</span>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('minlength')">
                        <span>Min length is {{control.errors.minlength.requiredLength}}.</span>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        <span>Max length is {{control.errors.maxlength.requiredLength}}.</span>
                    </ng-container>
                </ng-template>
            </div>

            <div class="form-item">
                <label>Confirm Password</label>
                <nz-form-control nzErrorTip="Password is not match.">
                    <input formControlName="confirmPassword" nzSize="large" nz-input type="password" />
                </nz-form-control>
            </div>

            <div class="form-item">
                <label>Social Account</label>
                <p class="mt-10">Please select a platform to bind your social account.</p>
                <div class="d-flex width-100p justify-content-start align-items-stretch">
                    <div>
                        <nz-form-control>
                            <nz-select
                                formControlName="socialPlatform"
                                style="width: 150px; border-radius: 0"
                                nzSize="large"
                                [nzPlaceHolder]="'Social Media'"
                            >
                                <nz-option
                                    *ngFor="let socialMedia of socialMedias"
                                    [nzLabel]="socialMedia.name"
                                    [nzValue]="socialMedia.value"
                                ></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </div>

                    <nz-form-control nzErrorTip="Social account cannot be empty.">
                        <input
                            formControlName="socialAccount"
                            nzSize="large"
                            class="flex-1"
                            nz-input
                            type="text"
                            style="margin-top: 0; border-left: 0; border-radius: 0"
                        />
                    </nz-form-control>
                </div>
            </div>

            <button
                class="submit-btn color1 bg-color3 width-100p mt-30"
                nzSize="large"
                nz-button
                [nzLoading]="submitting"
                [disabled]="signUpFrom.invalid"
                (click)="signUp()"
            >
                CREATE
            </button>
        </form>
    </div>
</section>
