<section class="reset-password-box font-body">
    <h1 class="font-title">Reset your Password</h1>

    <ng-container *ngIf="!key">
        <p class="text-align-center mb-30">We will send you an email to reset your password</p>

        <div class="form-item">
            <label>Email</label>
            <input nzSize="large" nz-input type="text" [(ngModel)]="email" (keyup.enter)="sendResetRequest()" />
            <p *ngIf="startResetError" class="red">{{startResetError}}</p>
        </div>

        <button
            class="submit-btn color1 bg-color3 width-100p mt-30"
            nzSize="large"
            nz-button
            [disabled]="!email"
            [nzLoading]="submitting"
            (click)="sendResetRequest()"
        >
            CREATE
        </button>
    </ng-container>

    <ng-container *ngIf="key">
        <div class="form-item">
            <label>New Password</label>
            <input nzSize="large" nz-input type="password" [(ngModel)]="password" />
            <p class="red" *ngIf="password && password.length < 8 " lass="red">Min length is 8.</p>
            <p class="red" *ngIf="password && password.length > 20 " lass="red">Max length is 20.</p>
        </div>

        <div class="form-item">
            <label>Confirm Password</label>
            <input nzSize="large" nz-input type="password" [(ngModel)]="confirmPassword" (keyup.enter)="finishReset()" />
            <p class="red" *ngIf="password && confirmPassword && password !== confirmPassword " lass="red">Password is not match.</p>
        </div>

        <button
            class="submit-btn color1 bg-color3 width-100p mt-30"
            nzSize="large"
            nz-button
            [disabled]="!isPasswordValid"
            [nzLoading]="submitting"
            (click)="finishReset()"
        >
            SUBMIT
        </button>
    </ng-container>

    <div class="text-decoration-underline create-account" [routerLink]="['/','sign-in']">
        <span class="cursor-pointer">Cancel</span>
    </div>
</section>
