<div id="crowdfunding" *ngIf="shouldCrowdFundingVisible">
    <div *ngIf="crowdfundingStatus === 'inProgress'" class="title">
        <i nz-icon class="mr-10 fs-20 vertical-align-middle" nzType="antd:clock"></i>
        <span>Available for a limited time only!</span>
    </div>

    <div class="times">
        <div
            *ngFor="let item of timeNumberArray;let i = index;"
            [ngClass]="{
                'number-block':(i+1) % 3 !== 0,
                'dot':(i+1) % 3 === 0
            }"
        >
            {{item}}
        </div>
    </div>

    <div class="times name-times">
        <div class="name">Days</div>
        <div class="name"></div>

        <div class="name">Hours</div>
        <div class="name"></div>

        <div class="name">Minutes</div>
        <div class="name"></div>

        <div class="name">Seconds</div>
    </div>

    <div class="sold-info">
        <div>
            <i nz-icon class="mr-10 fs-22" nzType="antd:selling_label" style="vertical-align: text-bottom"></i>

            <span class="current-sold-count">{{crowdfundingDetail.item_sold}}</span>
            <span class="fs-14">
                <span> Sold of </span>
                <span class="total-goal-count">{{crowdfundingDetail.minimal_quantity}}</span>
            </span>
        </div>

        <div class="fs-18">
            <span class="percent">{{percentageRate}}% </span>
            <span>funded</span>
        </div>
    </div>
    <div class="progress-bar">
        <div class="current-bar" [style.width]="(percentageRate > 100 ? 100 : percentageRate) + '%'"></div>
    </div>

    <p class="desc mt-30">
        <ng-container *ngIf="crowdfundingStatus === 'inProgress'">
            This product will only be produced if at least
            <span class="total-goal-count"> {{crowdfundingDetail.minimal_quantity}} </span>
            units are supported by
            <span class="end-time"> {{formattedEndDate}} </span>
            .
        </ng-container>
    </p>
    <p class="desc mt-30">
        <i nz-icon nzType="antd:ship"></i>
        Ships {{shipDate}}
    </p>
</div>
