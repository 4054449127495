import { AnalyticsEventEnum } from './../../services/firebase-event.service';
import { Component, OnInit } from '@angular/core';
import { ShopService } from '@src/app/services/shop.service';
import { GlobalModalsService } from '@services/global-modals.service';
import { FirebaseEventService } from '@services/firebase-event.service';
import { UtilsService } from '@src/app/services/utils.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less'],
})
export class HomeComponent implements OnInit {
    productList: any[];

    get selectedProduct() {
        return (this.productList || [])[0];
    }

    constructor(public shopService: ShopService, public utilsService: UtilsService, private firebaseEventService: FirebaseEventService) {}

    ngOnInit(): void {
        this.shopService.getFavoriteProducts().then(res => {
            this.productList = res;
            this.logAnalyticsEvent();
        });
    }

    logAnalyticsEvent() {
        if (!this.productList.length) {
            return;
        }

        let products = this.productList;

        if (this.shopService.themeSettings.recommended.layout === 'one_product') {
            products = [this.productList[0]];
        }

        this.firebaseEventService.logEvent(AnalyticsEventEnum.VIEW_ITEM_LIST, {
            items: products.map(productItem => ({
                item_id: productItem.product_id,
                item_name: productItem.title,
                price: productItem.price,
                currency: productItem.currency,
            })),
            item_list_name: 'recommend_products',
        });
    }
}
