import { ShopService } from '@services/shop.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ShopifyService } from '@services/shopify.service';
import { map } from 'lodash';
import { GlobalModalsService } from '@services/global-modals.service';
import { FirebaseEventService, AnalyticsEventEnum } from '@services/firebase-event.service';

@Component({
    selector: 'app-single-product',
    templateUrl: './single-product.component.html',
    styleUrls: ['./single-product.component.less'],
})
export class SingleProductComponent implements OnInit, OnChanges {
    @Input() productId;

    imgSwiperIndex = 0;

    productCount = 1;
    productDetail;

    selectedVariantData;
    selectedOptions = {};
    submitting = false;

    crowdFundingDetail: any;

    public bigImgSwiperConfig: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 0,
    };

    public smallImgSwiperConfig: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 4,
        spaceBetween: 20,
    };

    get selectedVariantCompareAtPrice() {
        if (this.selectedVariantData && this.selectedVariantData.compareAtPrice) {
            return this.selectedVariantData.compareAtPrice;
        }
        return false;
    }

    get selectedVariantIsAvailable() {
        if (this.selectedVariantData && this.selectedVariantData.available) {
            return true;
        }
        return false;
    }

    constructor(
        public shopService: ShopService,
        public globalModalService: GlobalModalsService,
        private shopifyService: ShopifyService,
        private firebaseEventService: FirebaseEventService
    ) {}

    ngOnInit(): void {}

    ngOnChanges(e: SimpleChanges) {
        if (e.productId?.currentValue) {
            this.shopifyService.fetchingProductById(this.productId).then(data => {
                this.productDetail = data;
                this.selectedVariantData = this.productDetail.variants.find(variant => variant.available) || this.productDetail.variants[0];
                this.selectedVariantData.selectedOptions.forEach(ele => {
                    this.selectedOptions[ele.name] = ele.value;
                });
                this.logViewItemEvent();
            });

            this.shopService.getCrowdFundingDetailByProductID(this.productId).then(data => (this.crowdFundingDetail = data));
        }
    }

    subtract() {
        this.productCount--;
    }

    add() {
        this.productCount++;
    }

    prevImgSwiper() {
        if (this.imgSwiperIndex > 0) {
            this.imgSwiperIndex--;
        }
    }

    nextImgSwiper() {
        if (this.imgSwiperIndex < this.productDetail.images.length - 1) {
            this.imgSwiperIndex++;
        }
    }

    addToCart() {
        this.submitting = true;
        this.logAddToCartEvent();
        this.shopifyService
            .addingLineItems({
                variantId: this.selectedVariantData.id,
                quantity: this.productCount,
            })
            .then(() => {
                this.globalModalService.showSideCart();
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    changeOption() {
        this.selectedVariantData = this.productDetail.variants.find(item => {
            const optionObj = {};
            item.selectedOptions.map(ele => {
                optionObj[ele.name] = ele.value;
            });
            return !map(optionObj, (value, key) => this.selectedOptions[key] === value).includes(false);
        });
    }

    logViewItemEvent() {
        this.firebaseEventService.logEvent(AnalyticsEventEnum.VIEW_ITEM, {
            items: [
                {
                    item_id: this.productId,
                    item_name: this.productDetail.title,
                },
            ],
        });
    }

    logAddToCartEvent() {
        this.firebaseEventService.logEvent(AnalyticsEventEnum.ADD_TO_CART, {
            items: [
                {
                    item_id: this.selectedVariantData.sku,
                    item_name: this.selectedVariantData.title,
                    price: this.selectedVariantData.price,
                    quantity: this.productCount,
                },
            ],
            value: this.selectedVariantData.price * this.productCount,
        });
    }
}
