import { ShopifyService } from '@services/shopify.service';
import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { GlobalModalsService } from '@services/global-modals.service';
import { slideInLeftOnEnterAnimation, slideOutLeftOnLeaveAnimation } from 'angular-animations';
import { Router } from '@angular/router';
import Headroom from 'headroom.js';

@Component({
    selector: 'app-global-header',
    templateUrl: './global-header.component.html',
    styleUrls: ['./global-header.component.less'],
    animations: [
        slideInLeftOnEnterAnimation({ anchor: 'slideIn', duration: 300 }),
        slideOutLeftOnLeaveAnimation({ anchor: 'slideOut', duration: 300 }),
    ],
})
export class GlobalHeaderComponent implements OnInit, AfterViewInit {
    mobileMenuVisible = false;

    menus = [
        { name: 'Home', url: [] },
        { name: 'Shop', url: ['product', 'product-list'] },
    ];

    constructor(
        public shopService: ShopService,
        public shopifyService: ShopifyService,
        private router: Router,
        private el: ElementRef,
        public globalModalService: GlobalModalsService
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        const headerEle: HTMLHeadElement = this.el.nativeElement.querySelector('.global-header');
        const headRoom = new Headroom(headerEle, { offset: 200 });
        headRoom.init();
    }

    navigate(menuItem) {
        this.mobileMenuVisible = false;
        this.router.navigate(['/', ...menuItem.url]);
    }

    goHome() {
        this.router.navigate(['/', 'home']);
    }

    goUser() {
        this.router.navigate(['/', 'sign-in']);
    }
}
