import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-quantity-input',
    templateUrl: './quantity-input.component.html',
    styleUrls: ['./quantity-input.component.less'],
})
export class QuantityInputComponent implements OnInit {
    @Input() count: number;

    @Output() countChange = new EventEmitter<number>();

    constructor() {}

    ngOnInit(): void {}

    down() {
        this.count--;
        this.countChange.emit(this.count);
    }

    up() {
        this.count++;
        this.countChange.emit(this.count);
    }
}
