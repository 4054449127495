<section class="home-container">
    <!-- Banner -->
    <app-text-image-banner></app-text-image-banner>

    <!-- Recommended Products -->
    <div class="web-content web-recommended">
        <h4
            *ngIf="shopService.themeSettings.recommended.layout !== 'one_product'"
            class="content-title color5 font-title"
            style="word-break: break-word"
            [innerHtml]="shopService.themeSettings.recommended.title | safeHtml"
            [ngClass]="{
                'product-title-swiper': shopService.themeSettings.recommended.layout === 'swiper_product',
                'product-title-grid': shopService.themeSettings.recommended.layout === 'four_product_grid'
            }"
        ></h4>

        <!-- one product -->
        <app-single-product
            *ngIf="shopService.themeSettings.recommended.layout === 'one_product' && productList?.length"
            [productId]="selectedProduct?.product_id"
        ></app-single-product>

        <!-- swiper product -->
        <app-swiper-product
            *ngIf="shopService.themeSettings.recommended.layout === 'swiper_product'"
            [productList]="productList"
        ></app-swiper-product>

        <!-- four product grid -->
        <app-four-product-grid
            *ngIf="shopService.themeSettings.recommended.layout === 'four_product_grid'"
            [productList]="productList"
        ></app-four-product-grid>
    </div>

    <!-- About -->
    <app-text-image></app-text-image>

    <!-- Social Media -->
    <app-social-media></app-social-media>
</section>
