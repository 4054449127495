import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    get screenW() {
        return document.body.clientWidth;
    }

    get isDesktop() {
        return this.screenW > 768;
    }

    get isMobile() {
        return this.screenW <= 768;
    }

    constructor() {}
}
