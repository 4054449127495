import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';

interface SignUpData {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    socialMedia: string;
    socialMediaAccount: string;
    login?: any;
}

export interface OrderItem {
    id: any;
    name: string;
    createdAt: string;
    updatedAt: string;
    totalPrice: 0.0;
    financialStatus: string;
    fulfillmentStatus: string;
    shopId: number;
    shopName: string;
    customerId: any;
    customer: any;
    cancelledAt: string;
    orderLineItemList: {
        id: number;
        orderId: any;
        productName: string;
        variantName: string;
        productSku: string;
        quantity: number;
        price: number;
        totalDiscount: number;
        imageUrl: string;
    }[];
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private requestService: RequestService) {}

    login(data: { username: string; password: string }) {
        return this.requestService.sendRequest<{ id_token: string }>({
            url: '/customer/authenticate',
            method: 'POST',
            api: 'customer-api',
            data,
        });
    }

    signup(data: SignUpData) {
        // POST /customer/register;
        return this.requestService.sendRequest<any>({
            url: '/customer/register',
            method: 'POST',
            api: 'customer-api',
            data,
        });
    }

    sendResetEmail(email: string, redirectLocation: string) {
        return this.requestService.sendRequest<any>({
            url: '/customer/reset_password/request',
            method: 'POST',
            api: 'customer-api',
            data: { email, redirectLocation },
        });
    }

    resetPassword(newPassword: string, oobCode: string) {
        return this.requestService.sendRequest<any>({
            url: '/customer/reset_password/finish',
            method: 'POST',
            api: 'customer-api',
            data: { key: oobCode, newPassword },
        });
    }

    getOrders() {
        const token = localStorage.getItem('token');
        return this.requestService.sendRequest<any>({
            url: '/customer/account/my/orders',
            method: 'GET',
            api: 'customer-api',
            token: `Bearer ${token}`,
        });
    }
}
