// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    supportedLocale: ['en-US', 'zh-CN', 'id'],
    campaignService: 'https://campaign-test.lifo.ai',
    storageUrl: 'https://storage.googleapis.com',
    shopApiService: 'https://shop-api-test.lifo.ai',
    dataApiUrl: 'https://data-api-test.lifo.ai',
    customerApiUrl: 'https://customer-api-test-4lladlc2eq-uc.a.run.app',
    firebase: {
        apiKey: 'AIzaSyDPJcldPJzr3-UlpbxX9_qlHAZ7OSnqcBQ',
        authDomain: 'influencer-272204.firebaseapp.com',
        databaseURL: 'https://influencer-272204.firebaseio.com',
        projectId: 'influencer-272204',
        storageBucket: 'influencer-272204.appspot.com',
        messagingSenderId: '65044462485',
        appId: '1:65044462485:web:5f4015353f217ee2c2549c',
        measurementId: 'G-5DR8MKFJSN',
    },
    stripe: {
        token: 'pk_test_51HP08RJVRQRRLfBwHrEVd2YIbNrKpOvqIeSVQG7e6gLd23byQPwL5FFzMLmPkFXBxMyoGvkAyf3S477xcd8zlYg100iOzWclpK',
    },
    paypal: {
        clientId: 'ARDnEtpRWmGz1gfGZXysgQPP4uS8_ZeKSwx0Qw7WMZLMQngWDYQu__geGuID2S9ZuAsLeEBXSAEX_ySG',
        apiUrl: 'https://api.sandbox.paypal.com',
        websiteUrl: 'https://www.sandbox.paypal.com',
    },
    plaid: {
        env: 'sandbox',
    },
    shopHost: 'https://shop.lifo.ai',
    shopify: {
        domain: 'lifo-storefront-test.myshopify.com/',
        storefrontAccessToken: 'b1b00292586c932f4e8351b9370b548b',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
