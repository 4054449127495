import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { merge, mergeWith } from 'lodash';
import { FirebaseEventService } from '@services/firebase-event.service';

@Injectable({
    providedIn: 'root',
})
export class ThemeSettingsResolverService implements Resolve<any> {
    defaultThemeSettings = {
        announcement: 'Welcome To My Store',
        themeStyle: 'dark',
        cornerStyle: 'straight',
        fontTitle: "'Lora', serif",
        fontBody: "'Work Sans', sans-serif",
        color1: '#FFFFFF',
        color2: '#000000',
        color3: '#000000',
        color4: '#FFFFFF',
        color5: '#000000',
        color6: '#000000',
        banner: {
            title: 'Welcome To My Store',
            content: 'Check out my design just for your!',
            mobileImg: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-banner.png',
            desktopImg: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-banner.png',
            layout: 'on_the_image',
            mobileTextPosition: 'top',
            heroButtonText: 'SHOP NOW',
            heroButtonLink: '/product/product-list',
            heroButtonVisible: true,
        },
        recommended: {
            layout: 'swiper_product',
            title: 'Recommended Products',
        },
        aboutMe: {
            content:
                'Welcome to my shop. You can buy your favorite products designed by me. If you' +
                ' want to know more about me, welcome to visit my social media account via the quick link at the bottom.',
            portraitImage: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-portrait.png',
            title: 'About Me',
            direction: 'reverse',
            desktopBackgroundImage: '',
            mobileBackgroundImage: '',
            mobilePortraitImageStyle: 'full_size',
            youtubeVideoUrl: '',
        },
        logoImage: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-logo.png',
        socialMedia: {
            instagram: '',
            twitter: '',
            facebook: '',
            youtube: '',
            snapchat: '',
            spotify: '',
            tiktok: '',
            pinterest: '',
            linkedin: '',
            tumblr: '',
            discord: '',
            patreon: '',
            medium: '',
            applePodcasts: '',
        },
        socialMediaSection: {
            desktopBackgroundImage: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-social-media.png',
            mobileBackgroundImage:
                'https://storage.googleapis.com/influencer-272204.appspot.com/public/theme/default-social-media-mobile.png',
        },
    };

    constructor(private shopService: ShopService, private router: Router, private firebaseEventService: FirebaseEventService) {}

    resolve() {
        if (this.shopService.themeSettings) {
            return Promise.resolve();
        }

        return this.getThemeSettings().catch(() => this.router.navigateByUrl('/page-not-found'));
    }

    getThemeSettings() {
        return this.shopService.getShopDetail().then(data => {
            this.firebaseEventService.addShopIdForDefaultEvent(data.id);

            this.shopService.themeSettings = merge({}, this.defaultThemeSettings, data.theme_settings || {}) as any;

            // If no social media channel add, add lifo instagram
            if (Object.values(this.shopService.themeSettings.socialMedia).every(item => !item)) {
                this.shopService.themeSettings.socialMedia.instagram = 'https://www.instagram.com/lifoinc/';
            }

            this.shopService.setThemeClass();
            return Promise.resolve();
        });
    }
}
