import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';

export enum SubscribePlanNameEnum {
    STARTER = 'starter',
    EXPERIENCED = 'experienced',
    ADVANCED = 'advanced',
    PRO = 'pro',
    PREMIUM = 'premium',
}

export interface SubscriptionOfShopProfile {
    billing_status: 'failed' | string;
    product_sourcing_quota: number;
    product_sourcing_used: number;
    order_fulfillment_quota: number;
    order_fulfillment_used: number;
    last_billing_date: string;
    next_billing_date: string;
    sub_plan_id: SubscribePlanNameEnum;
    next_sub_plan_id?: SubscribePlanNameEnum;
    prev_sub_plan_id?: SubscribePlanNameEnum;
    sub_end_date: string;
    trial_start_date: string;
    trial_end_date: string;
}

export type ThemeSettings = Partial<FullThemeSettings>;

export interface FullThemeSettings {
    themeStyle: string;
    cornerStyle: 'round' | 'straight';
    fontTitle: string;
    fontBody: string;
    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5: string;
    color6: string;
    announcement: string;
    banner: {
        title: string;
        content: string;
        mobileImg: string;
        desktopImg: string;
        layout: string;
        mobileTextPosition: string;
        heroButtonVisible: boolean;
        heroButtonText?: string;
        heroButtonLink?: string;
    };
    recommended: {
        title: string;
        layout: string;
    };
    aboutMe: {
        content: string;
        portraitImage: string;
        mobilePortraitImage: string;
        title: string;
        direction: string;
        youtubeVideoUrl?: string;
        mobilePortraitImageStyle: string;
        desktopBackgroundImage: string;
        mobileBackgroundImage: string;
    };
    logoImage: string;
    socialMedia: {
        instagram: string;
        twitter: string;
        facebook: string;
        youtube: string;
        snapchat: string;
        spotify: string;
        tiktok: string;
        pinterest: string;
        linkedin: string;
        tumblr: string;
        discord: string;
        patreon: string;
        medium: string;
        applePodcasts: string;
    };
    socialMediaSection: {
        desktopBackgroundImage: string;
        mobileBackgroundImage: string;
    };
}

export interface ShopProfile {
    id: string;
    name: string;
    email: string;
    phone: string;
    shop_owner: string;
    domain: string;
    subscription_plan: SubscribePlanNameEnum;
    myshopify_domain: string;
    shop_detail: any;
    theme_settings: ThemeSettings;
    setup_complete: boolean;
    ship_to: any;
    theme_id: any;
    subscription?: SubscriptionOfShopProfile;
    service_fee_rate: number;
    user_id: string;
    available_credit_rate: number;
    shop_type: string;
    theme_app_token: string;
}

@Injectable({
    providedIn: 'root',
})
export class ShopService {
    fetchShopDetailIsCalled = false;

    loadingIcon = 'assets/imgs/loading-icon.jpg';

    get shopName() {
        return 'Lifo Storefront';
    }

    get color1() {
        return this.themeSettings?.color1;
    }
    get color2() {
        return this.themeSettings?.color2;
    }
    get color3() {
        return this.themeSettings?.color3;
    }
    get color4() {
        return this.themeSettings?.color4;
    }
    get color5() {
        return this.themeSettings?.color5;
    }
    get color6() {
        return this.themeSettings?.color6;
    }

    get cornerStyle() {
        return this.themeSettings?.cornerStyle;
    }

    get borderRadius() {
        if (this.cornerStyle === 'straight') {
            return '0';
        } else {
            return '20px';
        }
    }

    get fontTitle() {
        return this.themeSettings?.fontTitle;
    }

    get fontBody() {
        return this.themeSettings?.fontBody;
    }

    get logo() {
        return this.themeSettings?.logoImage;
    }

    productsInShoppingCart;

    themeSettings: ThemeSettings;
    shopDetail: ShopProfile;

    private fontListMap = new Map([
        ["'Archivo', sans-serif", 'Archivo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700'],
        ["'Karla', sans-serif", 'Karla:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800'],
        ["'Knewave', cursive", 'Knewave&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700'],
        ["'Lora', serif", 'Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700'],
        ["'Merriweather', serif", 'Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900'],
        ["'Montserrat', sans-serif", 'Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700'],
        ["'Nunito', sans-serif", 'Nunito:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700'],
        ["'Oswald', sans-serif", 'Oswald:wght@400;500;600;700'],
        ["'Playfair Display', serif", 'Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700'],
        ["'Poppins', sans-serif", 'Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700'],
        ["'Roboto', sans-serif", 'Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700'],
        ["'Ubuntu', sans-serif", 'Ubuntu:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700'],
        ["'Work Sans', sans-serif", 'Work+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700'],
    ]);

    constructor(private requestService: RequestService) {}

    getShopDetail() {
        return this.requestService
            .sendRequest<ShopProfile>({
                method: 'GET',
                api: 'shop-api',
                url: '/shared/shop_detail',
            })
            .then(res => {
                this.shopDetail = res;
                return res;
            })
            .finally(() => (this.fetchShopDetailIsCalled = true));
    }

    getShopProducts() {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            api: 'shop-api',
            url: '/shared/shop_products',
        });
    }

    getFavoriteProducts() {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            api: 'shop-api',
            url: '/shared/favorite_shop_products',
        });
    }

    subscribeEmail(email: string) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            api: 'shop-api',
            url: '/shared/add_subscribe_email',
            data: { email },
        });
    }

    getCrowdFundingDetailByProductID(productID: any) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            api: 'shop-api',
            url: `/shared/crowd_funding?product_id=${productID}`,
        });
    }

    setThemeClass() {
        const styleEle = document.createElement('style');
        const linkEle = document.createElement('link');
        const head = document.head || document.querySelector('head');
        const css = `
            .font-title{
                font-family:${this.fontTitle};
            }
            .font-body{
                font-family:${this.fontBody};
            }

            .color1{
                color:${this.color1} !important;
            }
            .color2{
                color:${this.color2} !important;
            }
            .color3{
                color:${this.color3} !important;
            }
            .color4{
                color:${this.color4} !important;
            }
            .color5{
                color:${this.color5} !important;
            }
            .color6{
                color:${this.color6} !important;
            }


            .border-radius{
                border-radius:${this.borderRadius};
            }
            .br-0{
                border-radius:0;
            }
            .border-tl-radius{
                border-top-left-radius:${this.borderRadius};
            }
            .border-tr-radius{
                border-top-right-radius:${this.borderRadius};
            }
            .border-bl-radius{
                border-bottom-left-radius:${this.borderRadius};
            }
            .border-br-radius{
                border-bottom-right-radius:${this.borderRadius};
            }

            .bg-color1{
                background-color:${this.color1} !important;
            }
            .bg-color2{
                background-color:${this.color2} !important;
            }
            .bg-color3{
                background-color:${this.color3} !important;
            }
            .bg-color4{
                background-color:${this.color4} !important;
            }
            .bg-color5{
                background-color:${this.color5} !important;
            }
            .bg-color6{
                background-color:${this.color6} !important;
            }`;

        head.appendChild(linkEle);
        const bodyFont = this.fontListMap.get(this.fontBody);
        const titleFont = this.fontListMap.get(this.fontTitle);
        const linkSrc = `https://fonts.googleapis.com/css2?family=${titleFont}&family=${bodyFont}&display=swap`;
        linkEle.type = 'text/css';
        linkEle.rel = 'stylesheet';
        linkEle.href = linkSrc;

        head.appendChild(styleEle);
        styleEle.appendChild(document.createTextNode(css));
    }
}
