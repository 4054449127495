import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { SharedModule } from '@shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
    declarations: [LoginComponent, SignUpComponent, OrderHistoryComponent, ResetPasswordComponent],
    imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule],
})
export class AuthModule {}
