import { Component, OnInit, AfterViewInit } from '@angular/core';
import { slideOutUpOnLeaveAnimation } from 'angular-animations';
import { ShopService } from '@services/shop.service';

@Component({
    selector: 'app-announcement-bar',
    templateUrl: './announcement-bar.component.html',
    styleUrls: ['./announcement-bar.component.less'],
    animations: [slideOutUpOnLeaveAnimation({ anchor: 'slideOut', duration: 300 })],
})
export class AnnouncementBarComponent implements OnInit {
    get announcement() {
        return this.shopService.themeSettings?.announcement;
    }

    visible = true;

    constructor(public shopService: ShopService) {}

    ngOnInit(): void {}

    closeAnnouncement() {
        (document.querySelector('.global-wrapper') as HTMLDivElement).style.paddingTop = '80px';
        this.visible = false;
    }
}
