import { Component, OnInit } from '@angular/core';
import { GlobalModalsService } from '@services/global-modals.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { ShopService } from '@services/shop.service';
import { Router } from '@angular/router';
import { ShopifyService } from '@services/shopify.service';

import { AnalyticsEventEnum, FirebaseEventService } from '@services/firebase-event.service';

@Component({
    selector: 'app-global-variant-selector',
    templateUrl: './global-variant-selector.component.html',
    styleUrls: ['./global-variant-selector.component.less'],
    animations: [
        fadeInOnEnterAnimation({ anchor: 'zoomIn', duration: 200 }),
        fadeOutOnLeaveAnimation({ anchor: 'zoomOut', duration: 200 }),
    ],
})
export class GlobalVariantSelectorComponent implements OnInit {
    productId: any;
    productDetail: any;
    count = 1;

    addingToCart = false;

    selectedVariant;
    selectedOptions: { name: string; value: string }[];
    optionsMap = new Map<string, any>();

    fetchingData = false;

    get shouldShowCompareAtPrice() {
        return this.selectedVariant.compareAtPrice && Number(this.selectedVariant.compareAtPrice) > Number(this.selectedVariant.price);
    }

    constructor(
        private globalModalService: GlobalModalsService,
        public shopService: ShopService,
        private router: Router,
        private shopifyService: ShopifyService,
        private firebaseEventService: FirebaseEventService
    ) {}

    ngOnInit(): void {
        this.globalModalService.productIdOfGlobalVariantModal$.subscribe(productId => {
            this.productId = productId;
            if (this.productId) {
                this.getProductDetail();
            } else {
                this.productId = null;
                this.productDetail = null;
                this.count = 1;
                this.selectedVariant = null;
                this.selectedOptions = null;
                this.optionsMap.clear();
            }
        });
    }

    close() {
        this.globalModalService.hideVariantSelectorModal();
        this.productDetail = null;
    }

    addToCart() {
        this.addingToCart = true;
        this.logAnalyticsEvent();
        this.shopifyService
            .addingLineItems({
                variantId: this.selectedVariant.id,
                quantity: this.count,
            })
            .then(() => {
                this.globalModalService.showSideCart();
                this.globalModalService.hideVariantSelectorModal();
            })
            .finally(() => (this.addingToCart = false));
    }

    optionChange() {
        this.selectedVariant = this.productDetail.variants.find(variantItem =>
            variantItem.selectedOptions.every(
                variantOption =>
                    this.selectedOptions.find(optionItem => optionItem.name === variantOption.name).value === variantOption.value
            )
        );
    }

    goDetail() {
        this.router.navigate(['/', 'product', 'product-details', this.productId]);
        this.close();
    }

    logAnalyticsEvent() {
        this.firebaseEventService.logEvent(AnalyticsEventEnum.ADD_TO_CART, {
            items: [
                {
                    item_id: this.productId,
                    item_name: this.productDetail.title,
                    price: this.selectedVariant.price,
                    quantity: this.count,
                    item_variant: this.selectedVariant.sku,
                },
            ],
            value: this.selectedVariant.price * this.count,
            currency: this.selectedVariant.priceV2?.currencyCode || 'USD',
        });
    }

    private getProductDetail() {
        this.fetchingData = true;
        this.shopifyService
            .fetchingProductById(this.productId)
            .then(data => {
                this.productDetail = data;
                this.selectedVariant = data.variants.find(item => item.available) || data.variants[0];
                this.selectedOptions = this.selectedVariant.selectedOptions.map(item => ({ name: item.name, value: item.value }));
                data.options.forEach(optionItem => {
                    this.optionsMap.set(optionItem.name, optionItem.values);
                });
            })
            .finally(() => (this.fetchingData = false));
    }
}
