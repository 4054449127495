<section *ngIf="sideCartVisible" [@containerFadeOut] class="side-cart-container">
    <div *ngIf="sideCartVisible" [@fadeIn] [@maskFadeOut] (click)="globalModalService.hideSideCart()" class="mask"></div>
    <section class="side-cart" *ngIf="sideCartVisible" [@slideIn] [@slideOut]>
        <i nz-icon nzType="close" class="fs-24 close-icon" (click)="globalModalService.hideSideCart()"></i>

        <div class="text-align-center fw-bold fs-24 top-title font-title">My Cart</div>

        <!-- TODO: Add error message -->

        <!-- Product List -->
        <div class="product-list">
            <div class="product-item" *ngIf="lineItems?.length === 0">
                <h3 class="fs-bold fs-18" style="margin: 0 auto">How Sad! Your bag is currently empty.</h3>
            </div>
            <div
                class="product-item"
                *ngFor="let lineItem of lineItems"
                appLoading
                [loading]="updatingLineItems.has(lineItem.id)"
                [iconVisible]="false"
                [maskVisible]="true"
            >
                <div class="left" [style.opacity]="lineItem.variant?'1':'0.5'">
                    <img *ngIf="lineItem.variant" [src]="lineItem.variant?.image.src" />
                    <span *ngIf="!lineItem.variant">Sold Out</span>
                </div>
                <div class="right">
                    <div class="right-item flex-2" [style.opacity]="lineItem.variant?'1':'0.5'">
                        <div class="fw-bold fs-14">{{lineItem.title}}</div>
                        <div class="fs-14 mt-10">
                            <span *ngFor="let option of lineItem.variant?.selectedOptions" class="mr-20">
                                {{option.name}}: {{option.value}}
                            </span>
                        </div>
                        <app-quantity-input
                            class="mt-10"
                            [count]="lineItem.quantity"
                            (countChange)="quantityChange(lineItem,$event)"
                        ></app-quantity-input>
                    </div>
                    <div class="right-item flex-1">
                        <div class="fw-bold" style="text-align: right">
                            <ng-container *ngIf="lineItem.variant;else soldOut">
                                <ng-container *ngIf="lineItem.discountAllocations.length>0">
                                    <span style="text-decoration: line-through">
                                        {{lineItem.variant?.price * lineItem.quantity | currency}}
                                    </span>
                                    <br />
                                </ng-container>
                                <span [class.red]="lineItem.discountAllocations.length>0">
                                    {{(lineItem.variant?.price * lineItem.quantity - lineItemsDiscountMap.get(lineItem)) | currency}}
                                </span>
                            </ng-container>

                            <ng-template #soldOut>$--</ng-template>
                        </div>
                        <div style="height: 32px; line-height: 32px">
                            <i nz-icon nzType="delete" class="fs-20 cursor-pointer" (click)="deleteProduct(lineItem)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Checkout -->
        <div class="checkout-section">
            <div class="subtotal fw-bold fs-16">
                <span>Subtotal </span>
                <span>{{checkoutInfo?.subtotalPrice | currency}}</span>
            </div>
            <div class="fs-12">Shipping & Taxes Calculated at Checkout</div>
            <button
                nz-button
                nzSize="large"
                class="checkout-btn color1 bg-color3 border-radius"
                [nzLoading]="checkOuting"
                [disabled]="lineItems.length === 0"
                (click)="startCheckout()"
            >
                CHECKOUT
            </button>
        </div>
    </section>
</section>
