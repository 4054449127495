<section class="order-history-box font-body color2">
    <h1 class="font-title">My Account</h1>

    <p class="text-align-center text-decoration-underline">
        <span class="cursor-pointer" (click)="logout()">Log Out</span>
    </p>

    <ng-container *ngIf="orders?.length > 0">
        <div class="desktop-show" style="margin-top: 30px; font-size: 26px; font-weight: bold">Order History</div>

        <div class="orders-box">
            <div *ngFor="let orderItem of orders" class="order-item">
                <div
                    class="order-header fw-500 fs-16 pb-10 d-flex justify-content-between align-items-center"
                    style="border-bottom: 1px solid #ccc"
                >
                    <span class="order-id">Order ID {{orderItem.id}}</span>

                    <span>{{orderItem.createdAt | date:'MM/dd/yyyy'}}</span>
                </div>

                <div class="product-item mb-20" *ngFor="let productItem of orderItem.orderLineItemList">
                    <img [src]="productItem.imageUrl" alt="" />

                    <div class="info pl-20 flex-1">
                        <div class="overflow-ellipsis-1">{{productItem.productName}}</div>
                        <div>{{productItem.variantName}}</div>
                        <div class="d-flex justify-content-between align-items-center width-100p">
                            <span>{{productItem.price | currency}}</span>
                            <span>x{{productItem.quantity}}</span>
                            <span class="fw-600">{{productItem.price * productItem.quantity | currency}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="orders?.length === 0" class="text-align-center" style="margin: 50px auto">You haven’t placed any orders yet.</div>

    <div *ngIf="fetching" style="height: 200px" appLoading [loading]="true"></div>
</section>
