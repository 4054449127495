import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderItem, AuthService } from '@services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.less'],
})
export class OrderHistoryComponent implements OnInit {
    orders: OrderItem[];
    fetching = false;

    constructor(private router: Router, private authService: AuthService, private messageService: NzMessageService) {}

    ngOnInit(): void {
        this.getOrders();
    }

    logout() {
        localStorage.removeItem('token');
        this.router.navigate(['/', 'sign-in']);
    }

    getOrders() {
        this.fetching = true;
        this.authService
            .getOrders()
            .then(data => (this.orders = data || []))
            .catch((err: HttpErrorResponse) => {
                if (err.status === 403) {
                    this.logout();
                } else {
                    this.messageService.error('Oops something went wrong, please try again later.');
                }
            })
            .finally(() => (this.fetching = false));
    }
}
