<div class="grid-style-layout">
    <div class="desktop-show d-flex justify-content-between product-list">
        <div *ngFor="let data of productList" class="d-flex align-items-center justify-content-between product-item">
            <div class="flex-1 product-info">
                <h5 class="overflow-ellipsis-2 cursor-pointer product-name font-title" (click)="goProductDetail(data.product_id)">
                    {{data.title}}
                </h5>
                <p class="product-price font-body">
                    <span *ngIf="data.compare_at_price && data.compare_at_price > data.price" class="compare-price">
                        ${{data.compare_at_price | number: "1.2-2"}}
                    </span>
                    <span>${{data.price | number: "1.2-2"}}</span>
                </p>
                <button
                    nz-button
                    class="add-to-cart bg-color3 color1 font-body"
                    [ngClass]="{'corner-round ': shopService.themeSettings.cornerStyle === 'round'}"
                    (click)="addToCart(data.product_id)"
                >
                    ADD TO CART
                </button>
            </div>
            <img
                [src]="data.image"
                class="cursor-pointer"
                [ngClass]="{'corner-round ': shopService.themeSettings.cornerStyle === 'round'}"
                (click)="goProductDetail(data.product_id)"
            />
        </div>
    </div>
    <div class="mobile-show mobile-product">
        <div *ngFor="let data of productList" style="display: inline-block; vertical-align: top; width: 44%; margin-left: 4%">
            <app-product-panel [productInfo]="data"></app-product-panel>
        </div>
    </div>
</div>
