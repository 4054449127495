<section *ngIf="productId" [@zoomIn] [@zoomOut] class="global-variant-selector">
    <div class="variant-selector-box p-20" appLoading [loading]="fetchingData">
        <i nz-icon nzType="close" class="close-icon" (click)="close()"></i>

        <ng-container *ngIf="productDetail">
            <div class="product-info">
                <div class="left">
                    <img [src]="productDetail.images[0].src" class="object-fit-contain" />
                </div>
                <div class="right">
                    <div class="desktop-show fs-18 fw-bold">{{productDetail.title}}</div>
                    <div class="mobile-show fs-16 fw-bold">{{productDetail.title}}</div>
                    <div>
                        <span *ngIf="shouldShowCompareAtPrice" class="compare-at-price">
                            {{selectedVariant.compareAtPrice | currency}}
                        </span>
                        <span class="fs-18">{{selectedVariant.price | currency}}</span>
                    </div>
                </div>
            </div>

            <div *ngFor="let optionItem of selectedOptions" class="option-item mb-20 mt-10">
                <div>{{optionItem.name}}:</div>
                <nz-select
                    class="width-100p"
                    [nzPlaceHolder]="optionItem.name"
                    [(ngModel)]="optionItem.value"
                    (ngModelChange)="optionChange()"
                >
                    <nz-option *ngFor="let val of optionsMap.get(optionItem.name)" [nzLabel]="val.value" [nzValue]="val.value"></nz-option>
                </nz-select>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-20">
                <span class="fs-18">Choose Quantity:</span>
                <app-quantity-input [(count)]="count"></app-quantity-input>
            </div>

            <button
                nzSize="large"
                class="mt-20 add-to-cart mb-20 bg-color3 color1 font-body"
                nz-button
                [nzLoading]="addingToCart"
                [disabled]="!selectedVariant.available"
                [style.borderRadius]="shopService.borderRadius"
                (click)="addToCart()"
            >
                {{selectedVariant.available ?'Add to Cart':'Sold Out'}}
            </button>

            <div class="text-align-center">
                <span class="hover-underline" (click)="goDetail()" style="padding: 2px 0">View Full Detail ></span>
            </div>
        </ng-container>

        <div *ngIf="!fetchingData && !productDetail" class="no-data">No Data</div>
    </div>
</section>
