import { Component, OnInit, Input } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { UtilsService } from '@src/app/services/utils.service';

enum SocialMediaEnum {
    TWITTER = 'twitter',
    YOUTUBE = 'youtube',
    FACEBOOK = 'facebook',
    SNAPCHAT = 'snapchat',
    INSTAGRAM = 'instagram',
    PINTEREST = 'pinterest',
    SPOTIFY = 'spotify',
    TIKTOK = 'tiktok',
    LINKEDIN = 'linkedin',
    TUMBLR = 'tumblr',
    DISCORD = 'discord',
    PATREON = 'patreon',
    MEDIUM = 'medium',
    APPLE_PODCASTS = 'applePodcasts',
    TWITCH = 'twitch',
    WEBSITE = 'website',
}

@Component({
    selector: 'app-social-media',
    templateUrl: './social-media.component.html',
    styleUrls: ['./social-media.component.less'],
})
export class SocialMediaComponent implements OnInit {
    // addedSocial = [];
    platforms: SocialMediaEnum[] = [
        SocialMediaEnum.TWITTER,
        SocialMediaEnum.YOUTUBE,
        SocialMediaEnum.FACEBOOK,
        SocialMediaEnum.SNAPCHAT,
        SocialMediaEnum.INSTAGRAM,
        SocialMediaEnum.PINTEREST,
        SocialMediaEnum.SPOTIFY,
        SocialMediaEnum.TIKTOK,
        SocialMediaEnum.LINKEDIN,
        SocialMediaEnum.TUMBLR,
        SocialMediaEnum.DISCORD,
        SocialMediaEnum.PATREON,
        SocialMediaEnum.MEDIUM,
        SocialMediaEnum.APPLE_PODCASTS,
        SocialMediaEnum.TWITCH,
        SocialMediaEnum.WEBSITE,
    ];

    get addedSocial() {
        return this.platforms.filter(platform => (this.shopService.themeSettings.socialMedia || {})[platform]);
    }

    get getBackgroundImage() {
        if (this.utilsService.isDesktop) {
            return this.shopService.themeSettings?.socialMediaSection?.desktopBackgroundImage;
        } else {
            return this.shopService.themeSettings?.socialMediaSection?.mobileBackgroundImage;
        }
    }

    constructor(public shopService: ShopService, private utilsService: UtilsService) {}

    ngOnInit(): void {}

    getNewSocialIcon(platform: SocialMediaEnum) {
        return `antd:social_media_${platform}`;
    }
}
