<div class="product-item">
    <div style="position: relative" (click)="goProductDetail(productInfo.product_id)">
        <div *ngFor="let tag of tags" class="tag-item">
            <img *ngIf="tag === 'crowdfunding'" src="assets/imgs/badge-crowdfunding.png" height="48" />
        </div>

        <div class="product-img">
            <img
                [lazyLoad]="productInfo.image"
                class="cursor-pointer"
                [ngClass]="{'corner-round ': shopService.themeSettings.cornerStyle === 'round' && utilsService.isMobile}"
                appImgLoadSmooth
            />
        </div>

        <div class="width-100p overflow-ellipsis-2 cursor-pointer product-name font-body mb-10">{{productInfo.title}}</div>
        <div class="product-price font-body fw-500 mb-10">
            <span *ngIf="productInfo.compare_at_price && productInfo.compare_at_price > productInfo.price" class="compare-price">
                ${{productInfo.compare_at_price | number: "1.2-2"}}
            </span>
            <span>${{productInfo.price | number: "1.2-2"}}</span>
        </div>
    </div>

    <button
        nz-button
        class="add-to-cart bg-color3 color1 font-body"
        [ngClass]="{'corner-round ': shopService.themeSettings.cornerStyle === 'round'}"
        (click)="addToCart(productInfo.product_id)"
    >
        ADD TO CART
    </button>
</div>
