import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
    loginFailedMessage: string;
    email = '';
    password = '';

    submitting = false;

    get submitBtnDisabled() {
        if (this.email && this.password.length >= 6) {
            return false;
        }

        return true;
    }

    constructor(private authService: AuthService, private router: Router, private messageService: NzMessageService) {}

    ngOnInit(): void {}

    login() {
        if (this.submitBtnDisabled) {
            return;
        }

        this.submitting = true;

        this.authService
            .login({ username: this.email, password: this.password })
            .then(res => {
                localStorage.setItem('token', res.id_token);
                this.router.navigate(['/', 'order-history']);
            })
            .catch((err: HttpErrorResponse) => {
                if (err.statusText === 'Unknown Error') {
                    this.messageService.error('Oops! Something went wrong. Please try again.');
                } else {
                    this.loginFailedMessage = err.error.message;
                }
            })
            .finally(() => (this.submitting = false));
    }
}
