<div
    class="d-flex about-content"
    [ngClass]="{
        'about-content-reverse': shopService.themeSettings.aboutMe?.direction === 'basic',
        'about-content-cover': shopService.themeSettings.aboutMe?.mobilePortraitImageStyle === 'full_size'
    }"
    [ngStyle]="{ background: aboutBackground }"
>
    <div
        class="d-flex align-items-center about-text"
        [ngStyle]="{
            'background-image': getBackgroundImage ? 'url(' + getBackgroundImage + ')' : '',
            'background-size': 'cover',
            'background-position': 'center'
        }"
    >
        <div class="width-100p">
            <h4 class="content-title color1 font-title" [innerHtml]="shopService.themeSettings.aboutMe.title | safeHtml"></h4>
            <p class="content-text color1 font-body" [innerHtml]="shopService.themeSettings.aboutMe.content | safeHtml"></p>
        </div>
    </div>

    <div *ngIf="aboutSectionMediaType === 'video'" class="about-video">
        <div
            class="width-100p height-100p"
            style="position: absolute; top: 0; right: 0"
            [ngStyle]="{ padding: shopService.themeSettings.aboutMe?.mobilePortraitImageStyle === 'full_size' || utilsService.isDesktop ? '0px' : '20px' }"
        >
            <iframe
                *ngIf="shopService.themeSettings.aboutMe.youtubeVideoUrl"
                width="100%"
                height="100%"
                [src]="embedYoutubeVideoUrl | safeUrl"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>

            <img
                *ngIf="!shopService.themeSettings.aboutMe.youtubeVideoUrl && shopService.themeSettings.aboutMe.portraitImage"
                appImgLoadSmooth
                [lazyLoad]="shopService.themeSettings.aboutMe.portraitImage"
                class="object-fit-cover width-100p height-100p"
            />
        </div>
    </div>
    <div *ngIf="aboutSectionMediaType === 'image'" class="about-img">
        <div
            class="width-100p height-100p"
            style="position: absolute; top: 0; right: 0"
            [ngStyle]="{ padding: shopService.themeSettings.aboutMe?.mobilePortraitImageStyle === 'full_size' || utilsService.isDesktop ? '0px' : '20px' }"
        >
            <img
                appImgLoadSmooth
                [lazyLoad]="shopService.themeSettings.aboutMe.portraitImage"
                class="object-fit-cover width-100p height-100p"
            />
        </div>
    </div>
</div>
