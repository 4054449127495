import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
    selector: 'app-swiper-product',
    templateUrl: './swiper-product.component.html',
    styleUrls: ['./swiper-product.component.less'],
})
export class SwiperProductComponent implements OnInit {
    @ViewChild('swiper') swiper: SwiperComponent;

    @Input() productList: any[];

    public swiperConfig: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 20,
        mousewheel: false,
        loop: false,
        breakpoints: {
            768: {
                slidesPerView: 3,
            },
        },
        keyboard: true,
        scrollbar: false,
        navigation: true,
        pagination: false,
    };

    constructor() {}

    ngOnInit(): void {}
}
