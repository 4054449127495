import { Component, OnInit, Input } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { GlobalModalsService } from '@services/global-modals.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-four-product-grid',
    templateUrl: './four-product-grid.component.html',
    styleUrls: ['./four-product-grid.component.less'],
})
export class FourProductGridComponent implements OnInit {
    @Input() productList;

    constructor(public shopService: ShopService, private globalModalService: GlobalModalsService, private router: Router) {}

    ngOnInit(): void {}

    addToCart(productId) {
        this.globalModalService.showVariantSelectorModal(productId);
    }

    goProductDetail(productId) {
        this.router.navigate(['/', 'product', 'product-details', productId]);
    }
}
