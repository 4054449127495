import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AntdModulesModule } from './libs/antd-modules.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoadingDirective } from './directives/loading.directive';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SideCartComponent } from './components/side-cart/side-cart.component';
import { AnnouncementBarComponent } from './components/announcement-bar/announcement-bar.component';
import { GlobalHeaderComponent } from './components/global-header/global-header.component';
import { GlobalFooterComponent } from './components/global-footer/global-footer.component';
import { TextImageBannerComponent } from './components/text-image-banner/text-image-banner.component';
import { TextImageComponent } from './components/text-image/text-image.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { ProductPanelComponent } from './components/product-panel/product-panel.component';
import { SingleProductComponent } from './components/single-product/single-product.component';
import { GlobalVariantSelectorComponent } from './components/global-variant-selector/global-variant-selector.component';
import { QuantityInputComponent } from './components/quantity-input/quantity-input.component';
import { ImgLoadTransitionDirective } from './pipes/img-loading.pipe';

import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { CrowdfundingComponent } from './components/crowdfunding/crowdfunding.component';

export class AppModule {}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
};
@NgModule({
    declarations: [
        LoadingDirective,
        SafeHtmlPipe,
        SafeUrlPipe,
        SideCartComponent,
        AnnouncementBarComponent,
        GlobalHeaderComponent,
        GlobalFooterComponent,
        TextImageBannerComponent,
        TextImageComponent,
        SocialMediaComponent,
        ProductPanelComponent,
        SingleProductComponent,
        GlobalVariantSelectorComponent,
        QuantityInputComponent,
        ImgLoadTransitionDirective,
        CrowdfundingComponent,
    ],
    imports: [CommonModule, AntdModulesModule, FormsModule, ReactiveFormsModule, RouterModule, SwiperModule, LazyLoadImageModule],
    exports: [
        AntdModulesModule,
        LoadingDirective,
        SafeHtmlPipe,
        SafeUrlPipe,
        RouterModule,
        AnnouncementBarComponent,
        GlobalHeaderComponent,
        SideCartComponent,
        GlobalFooterComponent,
        TextImageBannerComponent,
        TextImageComponent,
        SocialMediaComponent,
        ProductPanelComponent,
        SingleProductComponent,
        GlobalVariantSelectorComponent,
        QuantityInputComponent,
        SwiperModule,
        LazyLoadImageModule,
        ImgLoadTransitionDirective,
        AngularFireAnalyticsModule,
        CrowdfundingComponent,
    ],
    providers: [
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG,
        },
    ],
})
export class SharedModule {}
