import { Component, OnInit } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { GlobalModalsService } from '@services/global-modals.service';
import { ShopifyService } from '@services/shopify.service';
import { AnalyticsEventEnum, FirebaseEventService } from '@services/firebase-event.service';

import {
    slideInRightOnEnterAnimation,
    slideOutRightOnLeaveAnimation,
    fadeInOnEnterAnimation,
    fadeOutOnLeaveAnimation,
} from 'angular-animations';

@Component({
    selector: 'app-side-cart',
    templateUrl: './side-cart.component.html',
    styleUrls: ['./side-cart.component.less'],
    animations: [
        slideInRightOnEnterAnimation({ anchor: 'slideIn', duration: 300, delay: 100 }),
        slideOutRightOnLeaveAnimation({ anchor: 'slideOut', duration: 300, delay: 100 }),
        fadeInOnEnterAnimation({ anchor: 'fadeIn', duration: 300 }),
        fadeOutOnLeaveAnimation({ anchor: 'maskFadeOut', duration: 300 }),
        fadeOutOnLeaveAnimation({ anchor: 'containerFadeOut', duration: 10, delay: 300 }),
    ],
})
export class SideCartComponent implements OnInit {
    sideCartVisible: boolean;

    updatingLineItems = new Set<any>();

    checkOuting = false;

    get checkoutInfo() {
        return this.shopifyService.checkoutInfo;
    }

    get lineItems() {
        return this.checkoutInfo?.lineItems;
    }

    get lineItemsDiscountMap() {
        return this.shopifyService.lineItemsDiscountMap;
    }

    constructor(
        public shopService: ShopService,
        public globalModalService: GlobalModalsService,
        private shopifyService: ShopifyService,
        private firebaseEventService: FirebaseEventService
    ) {}

    ngOnInit(): void {
        this.globalModalService.sideCartVisible$.subscribe(visible => (this.sideCartVisible = visible));
        this.shopifyService.fetchingCheckoutInfo();
    }

    startCheckout() {
        this.logBeginCheckoutEvent();
        this.checkOuting = true;
        location.href = this.shopifyService.checkoutInfo.webUrl;
    }

    quantityChange(lineItem, quantity) {
        this.updateLineItem(lineItem, quantity);
    }

    deleteProduct(lineItem) {
        this.updatingLineItems.add(lineItem.id);
        this.shopifyService.removingLineItems([lineItem.id]).finally(() => this.updatingLineItems.delete(lineItem.id));
        this.logRemoveFromCartEvent(lineItem);
    }

    updateLineItem(lineItem, quantity) {
        this.updatingLineItems.add(lineItem.id);
        this.shopifyService.updatingLineItems({ id: lineItem.id, quantity }).finally(() => this.updatingLineItems.delete(lineItem.id));
    }

    private logRemoveFromCartEvent(lineItem) {
        this.firebaseEventService.logEvent(AnalyticsEventEnum.REMOVE_FROM_CART, {
            items: [
                {
                    item_id: lineItem.variant.sku,
                    item_name: lineItem.title,
                    price: lineItem.variant.price,
                    quantity: lineItem.quantity,
                },
            ],
        });
    }

    private logBeginCheckoutEvent() {
        this.firebaseEventService.logEvent(AnalyticsEventEnum.BEGIN_CHECKOUT, {
            items: this.lineItems.map(lineItem => ({
                item_id: lineItem.variant.sku,
                item_name: lineItem.title,
                price: lineItem.variant.price,
                quantity: lineItem.quantity,
            })),
            value: this.checkoutInfo.subtotalPrice,
        });
    }
}
