<header class="global-header fixed" style="top: 0; left: 0; width: 100%; z-index: 9">
    <!-- <app-announcement-bar></app-announcement-bar> -->

    <nav class="d-flex align-items-center justify-content-between fs-14 fw-bold" style="height: 80px">
        <div class="d-flex align-items-center justify-content-between h-100p" style="flex: 1">
            <div class="logo">
                <img [src]="shopService.logo" class="cursor-pointer" (click)="goHome()" />
            </div>

            <div class="desktop-show h-100p">
                <div class="nav-links h-100p">
                    <div class="link-item hover-underline" *ngFor="let menu of menus" (click)="navigate(menu)">{{menu.name}}</div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center align-items-center">
            <i nz-icon nzType="user" nzTheme="outline" class="fs-24 mr-20 cursor-pointer" (click)="goUser()"></i>
            <div class="cart-toggle cursor-pointer" style="margin-top: 4px" (click)="globalModalService.showSideCart()">
                <span class="cart-counter">{{shopifyService.totalQtyOfLineItems}}</span>
                <i class="shopping-cart-icon" nz-icon nzType="shopping" nzTheme="outline"></i>
            </div>
        </div>

        <div class="hamburger" style="margin-left: 10px" (click)="mobileMenuVisible = !mobileMenuVisible">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line3"></div>
        </div>
    </nav>
</header>

<div *ngIf="mobileMenuVisible" [@slideIn] [@slideOut] class="mobile-nav-links">
    <div class="d-flex justify-content-between align-items-center">
        <div class="logo">
            <img class="cursor-pointer" [lazyLoad]="shopService.logo" (click)="goHome()" />
        </div>
        <i nz-icon nzType="close" class="hamburger fs-24" (click)="mobileMenuVisible = !mobileMenuVisible"></i>
    </div>
    <div class="mt-30">
        <div *ngFor="let menu of menus" class="header-menu-item" (click)="navigate(menu)">{{menu.name}}</div>
    </div>
</div>
