<section class="global-wrapper" style="padding-top: 80px">
    <app-global-header></app-global-header>

    <div class="content" style="min-height: calc(100vh - 500px)">
        <router-outlet></router-outlet>
    </div>

    <app-global-footer></app-global-footer>

    <app-side-cart></app-side-cart>

    <app-global-variant-selector></app-global-variant-selector>
</section>
