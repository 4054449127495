import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SharedModule } from '@shared/shared.module';

import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { FourProductGridComponent } from './four-product-grid/four-product-grid.component';
import { SwiperProductComponent } from './swiper-product/swiper-product.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
};

@NgModule({
    declarations: [HomeComponent, FourProductGridComponent, SwiperProductComponent],
    imports: [CommonModule, SharedModule, SwiperModule],
    providers: [
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG,
        },
    ],
})
export class HomeModule {}
