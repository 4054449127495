import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

interface IRequestOptions {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    api?: string;
    data?: any;
    params?: HttpParams;
    token?: string;
}

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(private http: HttpClient) {}

    getHost(apiString: any) {
        switch (apiString) {
            case 'api-shop':
                return 'https://campaign-shop.lifo.ai';
            case 'data-api':
                return environment.dataApiUrl;
            case 'data-shop':
            case 'discover':
            case 'shop-api':
                return environment.shopApiService;
            case 'customer-api':
                return environment.customerApiUrl;
            default:
                return environment.campaignService;
        }
    }

    sendRequest$<T>(requestOptions: IRequestOptions, errorHandlerType: 'string' | 'object' = 'string'): Observable<T> {
        const host = this.getHost(requestOptions.api);

        const headersParams: any = { 'Content-Type': 'application/json', 'accept-language': 'en;q=0.9,zh-CN;q=0.8,zh;q=0.7' };
        if (requestOptions.token) {
            headersParams.Authorization = requestOptions.token;
        }

        const httpOptions = {
            headers: new HttpHeaders(headersParams),
        };

        const url = `${host ?? environment.campaignService}${requestOptions.url}`;

        switch (requestOptions.method) {
            case 'GET':
                return this.http.get<T>(url, httpOptions);
            case 'DELETE':
                return this.http.delete<T>(url, httpOptions);
            case 'POST':
                return this.http.post<T>(url, requestOptions.data, httpOptions);
            case 'PUT':
                return this.http.put<T>(url, requestOptions.data, httpOptions);
        }
    }

    sendRequest<T>(requestOptions: IRequestOptions, errorHandlerType: 'string' | 'object' = 'object'): Promise<T> {
        return this.sendRequest$<T>(requestOptions, errorHandlerType).pipe(take(1)).toPromise();
    }

    private handleError(error: HttpErrorResponse, errorHandlerType: 'string' | 'object' = 'string') {
        if (errorHandlerType === 'object') {
            return throwError({
                status: error.status,
                body: error.status < 500 ? error.error : 'Oops! Something went wrong. Please try again.',
            });
        } else {
            let error_message = '';
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                error_message = `An error occurred:', ${error.error.message}`;
            } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                error_message = `Backend returned code ${error.status}, body was: ${JSON.stringify(error.error)}`;
            }
            // return an observable with a user-facing error message
            return throwError(error_message);
        }
    }
}
