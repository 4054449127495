import { Component, OnInit } from '@angular/core';
import { ShopService } from '@services/shop.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.less'],
})
export class PageNotFoundComponent implements OnInit {
    constructor(private shopService: ShopService, private router: Router) {}

    ngOnInit(): void {
        if (!this.shopService.fetchShopDetailIsCalled) {
            this.router.navigateByUrl('/home');
        }
    }
}
