import { Injectable } from '@angular/core';
import Client from 'shopify-buy';
import { environment } from '@src/environments/environment';
import { ShopService } from '@services/shop.service';

@Injectable({
    providedIn: 'root',
})
export class ShopifyService {
    client: any;

    checkoutId: any;

    checkoutInfo: any;

    lineItemsDiscountMap = new Map();

    get totalQtyOfLineItems() {
        return this.checkoutInfo?.lineItems.reduce((prev, curr) => prev + curr.quantity, 0);
    }

    constructor(private shopService: ShopService) {}

    initializeClient() {
        this.client = Client.buildClient(environment.shopify);
        this.checkoutId = localStorage.getItem('checkoutId');
        if (!this.checkoutId) {
            this.createCheckout();
        }
    }

    createCheckout() {
        return this.client.checkout
            .create()
            .then(checkout => {
                localStorage.setItem('checkoutId', checkout.id);
                this.checkoutId = checkout.id;
            })
            .then(() =>
                this.client.checkout.updateAttributes(this.checkoutId, {
                    customAttributes: [{ key: 'shop_id', value: String(this.shopService.shopDetail.id) }],
                })
            );
    }

    // TODO: Need to add type
    fetchingCheckoutInfo(): Promise<any> {
        return this.client.checkout
            .fetch(this.checkoutId)
            .then(data => this.handleCheckoutInfo(data))
            .catch(() => {
                localStorage.clear();
                this.createCheckout().then(() => this.fetchingCheckoutInfo());
            });
    }

    fetchingProductById(id: any): Promise<any> {
        return this.client.product.fetch(this.encodingShopifyId(id));
    }

    addingLineItems(data: { variantId: any; quantity: number }): Promise<any> {
        return this.client.checkout.addLineItems(this.checkoutId, data).then(res => this.handleCheckoutInfo(res));
    }

    updatingLineItems(data: { id: any; quantity: number }): Promise<any> {
        return this.client.checkout.updateLineItems(this.checkoutId, data).then(res => this.handleCheckoutInfo(res));
    }

    removingLineItems(data: string[]): Promise<any> {
        return this.client.checkout.removeLineItems(this.checkoutId, data).then(res => this.handleCheckoutInfo(res));
    }

    addingDiscount(code: any): Promise<any> {
        return this.client.addDiscount(this.checkoutId, code);
    }

    removingDiscount(code: any): Promise<any> {
        return this.client.removeDiscount(this.checkoutId, code);
    }

    getShopifyIdFromBase64(base64Id: string): number {
        const decoded = atob(base64Id);
        const shopifyId = decoded.split('/').pop();
        return parseInt(shopifyId, 10);
    }

    encodingShopifyId(id: number): string {
        return btoa(`gid://shopify/Product/${id}`);
    }

    private handleCheckoutInfo(data) {
        // Remove Inactive Items
        const inactiveLineItemIds = data.lineItems.filter(item => !item.variant).map(item => item.id);
        if (inactiveLineItemIds.length > 0) {
            return this.removingLineItems(inactiveLineItemIds);
        } else {
            this.checkoutInfo = data;
            this.updateDiscount();
            return true;
        }
    }

    private updateDiscount() {
        this.checkoutInfo.lineItems.forEach(lineItem => {
            const discount = lineItem.discountAllocations.reduce((prev, curr) => prev + curr.allocatedAmount.amount, 0);
            this.lineItemsDiscountMap.set(lineItem, discount);
        });
    }
}
