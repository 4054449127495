import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.less'],
})
export class ResetPasswordComponent implements OnInit {
    email = '';
    startResetError: string;
    submitting = false;

    key: string;
    password: string;
    confirmPassword: string;

    get isPasswordValid() {
        return this.password && this.password.length >= 8 && this.password.length <= 20 && this.confirmPassword === this.password;
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private messageService: NzMessageService,
        private router: Router
    ) {}

    ngOnInit(): void {
        localStorage.removeItem('token');
        this.key = this.activatedRoute.snapshot.queryParamMap.get('key');
    }

    sendResetRequest() {
        if (!this.email) {
            return;
        }
        this.submitting = true;
        this.authService
            .sendResetEmail(this.email, `${window.location.origin}/reset-password`)
            .then(() => {
                this.messageService.success('Email Sent.');
                this.router.navigate(['/', 'sign-in']);
            })
            .catch(
                (err: HttpErrorResponse) => (this.startResetError = err.error.message || 'Oops! Something went wrong. Please try again.')
            )
            .finally(() => (this.submitting = false));
    }

    finishReset() {
        if (!this.isPasswordValid) {
            return;
        }
        this.submitting = true;
        this.authService
            .resetPassword(this.password, this.key)
            .then(() => {
                this.messageService.success('Success');
                this.router.navigate(['/', 'sign-in']);
            })
            .catch((err: HttpErrorResponse) =>
                this.messageService.error(err.error.message || 'Oops! Something went wrong. Please try again.')
            )
            .finally(() => (this.submitting = false));
    }
}
