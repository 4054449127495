import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!localStorage.getItem('token') && route.routeConfig.path === 'order-history') {
            this.router.navigate(['/', 'sign-in']);
            return false;
        }

        if (localStorage.getItem('token') && route.routeConfig.path !== 'order-history') {
            this.router.navigate(['/', 'order-history']);
            return false;
        }

        return true;
    }
}
