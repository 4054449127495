<div class="d-flex product-detail" *ngIf="productDetail">
    <div class="product-img">
        <div class="big-imgs">
            <swiper [config]="bigImgSwiperConfig" [(index)]="imgSwiperIndex" class="product-swiper">
                <div swiperSlide *ngFor="let item of productDetail.images">
                    <img [lazyLoad]="item.src" class="big-img" />
                </div>
            </swiper>
            <img
                *ngIf="productDetail.images?.length > 1"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjQ1NDYgMTcuNUgxMC42MDYxIiBzdHJva2U9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xNi45Njk3IDIzLjMzMzRMMTAuNjA2MSAxNy41MDAxTDE2Ljk2OTcgMTEuNjY2NyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVqb2luPSJiZXZlbCIvPgo8Y2lyY2xlIHI9IjE3IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAxNy41IDE3LjUpIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo="
                alt=""
                class="swiper-left"
                (click)="prevImgSwiper()"
            />
            <img
                *ngIf="productDetail.images?.length > 1"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjQ1NDYgMTcuNUgxMC42MDYxIiBzdHJva2U9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xNi45Njk3IDIzLjMzMzRMMTAuNjA2MSAxNy41MDAxTDE2Ljk2OTcgMTEuNjY2NyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVqb2luPSJiZXZlbCIvPgo8Y2lyY2xlIHI9IjE3IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAxNy41IDE3LjUpIiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo="
                alt=""
                class="swiper-right"
                (click)="nextImgSwiper()"
            />
        </div>
        <div class="small-imgs">
            <swiper class="desktop-show" [config]="smallImgSwiperConfig" [(index)]="imgSwiperIndex">
                <div
                    swiperSlide
                    *ngFor="let item of productDetail.images; let i = index"
                    [ngStyle]="{opacity: imgSwiperIndex === i ? '0.5' : '1'}"
                >
                    <img [lazyLoad]="item.src" (click)="imgSwiperIndex = i" />
                </div>
            </swiper>
        </div>
    </div>
    <div class="flex-1 product-info">
        <h5 class="content-title product-name font-title" style="text-align: left; color: #000">{{productDetail.title}}</h5>

        <div class="d-flex mb-20 product-price font-body">
            <span
                *ngIf="selectedVariantCompareAtPrice && selectedVariantCompareAtPrice * 1 > selectedVariantData.price * 1"
                class="compare-price"
            >
                ${{selectedVariantCompareAtPrice | number: "1.2-2"}}
            </span>
            <span>${{selectedVariantData?.price | number: "1.2-2"}}</span>
        </div>

        <app-crowdfunding *ngIf="crowdFundingDetail" [crowdfundingDetail]="crowdFundingDetail"></app-crowdfunding>

        <div
            *ngFor="let option of productDetail.options"
            class="d-flex align-items-center justify-content-between product-option font-body"
        >
            <span>{{option.name}}</span>
            <nz-select [(ngModel)]="selectedOptions[option.name]" nzSize="large" style="width: 300px" (ngModelChange)="changeOption()">
                <nz-option *ngFor="let item of option.values" [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
            </nz-select>
        </div>
        <div class="mt-10 d-flex align-items-center justify-content-between choose-qty font-body">
            <label class="desktop-show">Choose quantity</label>
            <app-quantity-input [(count)]="productCount"></app-quantity-input>
            <button
                nz-button
                class="mobile-show mb-20 add-to-cart bg-color3 color1 font-body"
                [ngClass]="{'corner-round ': shopService.themeSettings.cornerStyle === 'round'}"
                [disabled]="!selectedVariantIsAvailable"
                [nzLoading]="submitting"
                (click)="addToCart()"
            >
                {{selectedVariantIsAvailable ? 'ADD TO CART' : 'SOLD OUT'}}
            </button>
        </div>
        <button
            nz-button
            class="desktop-show mb-20 add-to-cart bg-color3 color1 font-body"
            [ngClass]="{'corner-round ': shopService.themeSettings.cornerStyle === 'round'}"
            [disabled]="!selectedVariantIsAvailable"
            [nzLoading]="submitting"
            (click)="addToCart()"
        >
            {{selectedVariantIsAvailable ? 'ADD TO CART' : 'SOLD OUT'}}
        </button>
        <div
            class="product-tip font-body"
            style="max-width: 100%; overflow-x: auto; word-break: break-word"
            [innerHTML]="productDetail.descriptionHtml | safeHtml"
        ></div>
    </div>
</div>
