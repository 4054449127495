import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ShopifyService } from '@services/shopify.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
    constructor(private shopifyService: ShopifyService) {}

    ngOnInit() {
        this.setRootVhForMobile();
        this.shopifyService.initializeClient();
    }

    setRootVhForMobile() {
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
            const vh2 = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh2}px`);
        });
    }
}
